<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
        <DefaultInput
            :label="$t('common_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_ForbiddenGoodsKeyword'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('forbiddenKeywords_ForbiddenGoodsKeyword.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="keyword"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="table-filter__item w-100 mw-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Status'])"></div>-->
<!--        <DefaultCheckbox-->
<!--                :value="status === 'true' || this.status === true"-->
<!--                :label="$t('forbiddenKeywords_IsActiveStatus.localization_value.value')"-->
<!--                @input="(val) => {this.status = val ? val : ''}"-->
<!--        />-->
<!--      </div>-->

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ScanFormDPFilter",
    components: {
      // DefaultCheckbox,
      FilterBlock,
      MainButton,
      DefaultInput,
      // DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc],

    data(){
      return {

        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        keyword: this.filterGetParams.keyword ? this.filterGetParams.keyword : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',

        filterCounts: [
          'id',
          'keyword',
          'status',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.keyword = newVal.keyword ? newVal.keyword : ''
        this.status = newVal.status ? newVal.status : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
