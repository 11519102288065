<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getScanFormDP.length > 0 && $store.getters.getScanFormDPLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_id',
            'common_create',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('common_id.localization_value.value') }}</th>
<!--            <th>{{ $t('forbiddenKeywords_ForbiddenGoodsKeyword.localization_value.value') }}</th>-->
            <th>{{ $t('common_create.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
            <th>{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getScanFormDP" :key="index">
            <td>
              #{{item.id}}
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td class="hide-1400"></td>
            <td class="manage-row">
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_download.localization_value.value')"
                        :type="'proforma'"
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + item.form_file.uuid"
                        :target="'_blank'"
                    />
<!--                        @click.native="downloadFile(item)"-->
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getScanFormDP"
                 :key="index"
            >
              <ScanFormDPTableMobile
                      :item="item"
                      @changeScanFormDPPopup="changeScanFormDPPopup(true, item.id)"
                      @deleteItem="deleteItem(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getScanFormDPCommonList.next_page_url !== null && $store.getters.getScanFormDP.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextScanFormDPPage}"
                    :count="$store.getters.getScanFormDPCommonList.total - $store.getters.getScanFormDPForPage * $store.getters.getScanFormDPCommonList.current_page < $store.getters.getScanFormDPForPage ?
                    $store.getters.getScanFormDPCommonList.total - $store.getters.getScanFormDPForPage * $store.getters.getScanFormDPCommonList.current_page:
                    $store.getters.getScanFormDPForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template
            v-if="$store.getters.getScanFormDPLoading === false && $store.getters.getScanFormDP.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

<!--    <ScanFormDPPopup-->
<!--            v-if="isModalScanFormDPPopup"-->
<!--            :itemId="itemId"-->
<!--            :type="'update'"-->
<!--            @reload="$emit('reload')"-->
<!--            @close="changeScanFormDPPopup(false)"-->
<!--    />-->
  </div>
</template>

<script>
  // import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import ScanFormDPPopup from "../../../../popups/ScanFormDPPopup/ScanFormDPPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ScanFormDPTableMobile from "./ScanFormDPTableMobile/ScanFormDPTableMobile";

  export default {
    name: "ScanFormDPTable",
    components: {
      ScanFormDPTableMobile,
      NoResult,
      // StatusIcoBtn,
      ManagerButton,
      LinkButton,
      ShowMore,
      // ScanFormDPPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: -1,
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalScanFormDPPopup: false,

      }
    },

    methods: {
      changeScanFormDPPopup(val, id = false) {
        if (id) {
          this.itemId = id
        }
        this.isModalScanFormDPPopup = val

        if (!val) {
          this.itemId = -1
        }
      },

      downloadFile(item) {
        if(!item?.form_file) {
          return
        }

        this.$store.dispatch('downloadFileFromServer', item.form_file.id).then(response => {
          this.createDownloadLink({data: response.data, name: `${item.form_file.original_filename}.${item.form_file.extension}`, type: item.form_file.extension})
        })
      },

      // deleteItem(id) {
      //   let text = {
      //     title: 'common_deleteConfirm',
      //     txt: '',
      //     yes: 'common_confirm',
      //     no: 'common_confirmNo'
      //   }
      //
      //   let confirm = () => {
      //     this.$store.dispatch('deleteScanFormDP', id).then(response => {
      //       if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
      //         this.openNotify('success', 'common_notificationRecordDeleted')
      //         this.$emit('reload')
      //       } else {
      //         this.openNotify('error', 'common_notificationUndefinedError')
      //       }
      //     })
      //
      //     this.$store.commit('setConfirmDeletePopup', false)
      //     return true
      //   }
      //
      //   this.deleteFunc(text, confirm)
      // },

    }
  }
</script>

<style scoped lang="scss">
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    .comment-row {
      width: 100px;
    }
  }

  .table-manage-list .manager-btn {
    width: 200px;
  }

  @media (max-width: 1400px) {
    .table-manage-list .manager-btn {
      width: 100px;
    }

    .user-field-admin {
      flex-wrap: wrap;
      display: flex;
    }

    .hide-1400 {
      display: none;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child {*/
  /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2) {*/
  /*padding-left: 0;*/
  /*}*/

  /*.site-table thead th:last-child,*/
  /*.site-table tbody td:last-child {*/
  /*padding-right: 0;*/
  /*}*/

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .size-24 {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }


</style>
